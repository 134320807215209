
import { LinkageConfigEntityModel, LinkageConfigQueryModel } from '@/entity-model/linkage-config-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, put } from './request';

const URL_PATH = `${RULE_BASE_REQUEST_PATH}/linkage/config`;

class LinkageConfigService implements ICRUDQ<LinkageConfigEntityModel, LinkageConfigQueryModel> {
    async create(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        // return new LinkageConfigEntityModel().toModel(res);
        return res;
    }

    async update(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: LinkageConfigQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // const res = {
        //     items: [
        //         { name: '消防报警联动摄像头', desc: '消防报警联动摄像头', createdTime: new Date().getTime() - 24 * 60 * 60 * 1000, status: 'true' }
        //     ]
        // };
        res.items = _.map(res.items, item => item = new LinkageConfigEntityModel().toModel(item));
        return res;
    }

    async enable(id: string, enable: boolean):Promise<any> {
        const url = `${URL_PATH}/enable`;
        const res = await put(url, { id, enable });
        return res;
    }

    async trigger(id: string):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/trigger`;
        const res = await post(url, { id });
        return res;
    }

    async saveConfig(params: any) {
        const url = `${URL_PATH}/design`;
        const res = await put(url, params);
        return res;
    }
}

export default new LinkageConfigService();
