

























































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { LinkageConfigEntityModel, LinkageConfigQueryModel } from '@/entity-model/linkage-config-entity';
import LinkageConfigService from '@/service/linkage-config';
import LinkageConfigDrawer from './linkage-config-drawer.vue';

@Component({
    components: {
        'linkage-config-drawer': LinkageConfigDrawer
    }
})
export default class LinkageConfigListComponent extends TableDialogFormComponent<LinkageConfigEntityModel, LinkageConfigQueryModel> {
    LinkageConfigEntityModel = LinkageConfigEntityModel;
    created() {
        this.initTable({
            service: LinkageConfigService,
            queryModel: new LinkageConfigQueryModel(),
            tableColumns: LinkageConfigEntityModel.getTableColumns()
        });
        this.getList();
    }
    enableClick(model: LinkageConfigEntityModel) {
        const enable = !model.enable;
        LinkageConfigService.enable(model.id, enable).then(() => {
            model.enable = !model.enable;
        });
    }
    detailClick(model: LinkageConfigEntityModel) {
        (this.$refs.linkageConfigDrawer as LinkageConfigDrawer).drawerShow(model);
    }
    triggerClick(model: LinkageConfigEntityModel) {
        LinkageConfigService.trigger(model.id).then(() => {
            this.showMessageSuccess('触发成功');
        });
    }
    logClick(model: LinkageConfigEntityModel) {
        this.showDeveloping();
    }
}

