

































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import { LinkageConfigEntityModel } from '@/entity-model/linkage-config-entity';
import CommonService from '@/service/common';
import DeviceService from '@/service/device';
import DeviceTypeService from '@/service/device-type';
import { LinkageDeviceEntityModel, LinkageDeviceQueryModel } from '@/entity-model/linkage-device-entity';
import FacilityGroupService from '@/service/facility-group';
import { ThingsModelTypeModel } from '@/entity-model/things-model-type-entity';
import { GroupEntityType } from '@/model/enum';
import LinkageConfigService from '@/service/linkage-config';

const triggerTypeList = [
    { label: '设备触发', value: 'DEVICE' },
    { label: '定时触发', value: 'TIME' }
];
const triggerMethodList = [
    { label: '事件触发', value: 'EVENT' },
    { label: '属性触发', value: 'ATTRIBUTE' }
];
const conditionTypeList = [
    { label: '设备状态', value: 'ATTRIBUTE' }
];
const operateList = [
    { label: '>', value: '>' },
    { label: '>=', value: '>=' },
    { label: '<', value: '<' },
    { label: '<=', value: '<=' },
    { label: '==', value: '==' },
    { label: '!=', value: '!=' }
];
const actionList = [
    { label: '大屏监控', value: 'LARGE_SCREEN_NOTICE' },
    { label: '设备输出', value: 'DEVICE_METHOD_ACTION' }
];
const actionTypeList = [
    { label: '播放视频监控', value: 'OpenCamera' }
];

@Component
export default class LinkageConfigDrawer extends DrawerComponent {
    linkageConfigModel: LinkageConfigEntityModel = null;
    triggerList: Array<any> = [];
    conditionList: Array<any> = [];
    actionList: Array<any> = [];
    deviceTypeList: Array<any> = null;
    deviceLinkList: Array<any> = null;

    created() {
        CommonService.getDeviceTypeOptions().then(res => {
            this.deviceTypeList = _.map(res, item => {
                return { label: item.name, value: item.value, title: item.name };
            });
        });
        FacilityGroupService.getGroupTree(GroupEntityType.LINKAGE).then(res => {
            this.deviceLinkList = res;
        });
    }

    get TriggerTypeList() {
        return triggerTypeList;
    }
    get TriggerMethodList() {
        return triggerMethodList;
    }
    get ConditionTypeList() {
        return conditionTypeList;
    }
    get OperateList() {
        return operateList;
    }
    get ActionList() {
        return actionList;
    }
    get ActionTypeList() {
        return actionTypeList;
    }
    get IsTimeTrigger() {
        return _.get(this.triggerList, '[0].type') === 'TIME';
    }
    get IsEventTrigger() {
        return _.get(this.triggerList, '[0].type') === 'DEVICE' && _.get(this.triggerList, '[0].triggerType') === 'EVENT';
    }
    get IsAttributeTrigger() {
        return _.get(this.triggerList, '[0].type') === 'DEVICE' && _.get(this.triggerList, '[0].triggerType') === 'ATTRIBUTE';
    }

    onDeviceTypeChange(deviceTypeId: string, triggerItem: any, clearValue: boolean = true) {
        if (deviceTypeId) {
            if (clearValue) {
                triggerItem.device = undefined;
                triggerItem.triggerType = undefined;
                triggerItem.event = undefined;
                triggerItem.attribute = undefined;
                triggerItem.operate = undefined;
                triggerItem.value = undefined;
            }
            DeviceService.getDeviceListByTypeId(deviceTypeId).then(res => {
                triggerItem.deviceList = res;
            });
            DeviceTypeService.retrieve(deviceTypeId).then(res => {
                triggerItem.eventList = _.map(_.get(res, 'events'), item => {
                    return {
                        label: item.name,
                        title: item.name,
                        value: item.id
                    };
                });
                triggerItem.attributeList = _.map(_.get(res, 'attrs'), item => {
                    return {
                        label: item.name,
                        title: item.name,
                        value: item.identifier,
                        attributeModel: item.thingsModelTypeModel as ThingsModelTypeModel
                    };
                });
                triggerItem.methodList = _.map(_.get(res, 'methods'), item => {
                    return {
                        label: item.name,
                        title: item.name,
                        value: item.id
                    };
                });
            });
        }
    }

    onDeviceAttributeChange(attributeIdentifier: string, conditionItem: any, clearValue: boolean = true) {
        if (clearValue) {
            conditionItem.operate = undefined;
            conditionItem.value = undefined;
        }
        const attribute = _.find(conditionItem.attributeList, item => item.value === attributeIdentifier);
        if (attribute) {
            conditionItem.attributeModel = attribute.attributeModel;
        }
    }

    drawerShow(model: LinkageConfigEntityModel) {
        this.linkageConfigModel = model;
        this.drawerOpen(null, `规则设计(${model.name})`);
        // this.addNewTrigger();
        // this.addNewCondition();
        // this.addNewAction();
        LinkageConfigService.retrieve(model.id).then(res => {
            if (!_.isEmpty(_.get(res, 'triggerConfig.eventTriggers'))) {
                this.triggerList = _.map(_.get(res, 'triggerConfig.eventTriggers'), item => {
                    const triggerItem = {
                        type: 'DEVICE',
                        product: item.productId,
                        device: item.deviceId,
                        triggerType: 'EVENT',
                        event: item.eventId,
                        deviceList: null,
                        eventList: null
                    };
                    this.onDeviceTypeChange(item.productId, triggerItem, false);
                    return triggerItem;
                });
            } else if (!_.isEmpty(_.get(res, 'triggerConfig.attributeTriggers'))) {
                this.triggerList = _.map(_.get(res, 'triggerConfig.attributeTriggers'), item => {
                    const triggerItem = {
                        type: 'DEVICE',
                        product: item.productId,
                        device: item.deviceId,
                        triggerType: 'ATTRIBUTE',
                        attribute: item.attributeKey,
                        operate: item.operate,
                        value: item.value,
                        deviceList: null,
                        attributeList: null
                    };
                    this.onDeviceTypeChange(item.productId, triggerItem, false);
                    this.onDeviceAttributeChange(item.attributeKey, triggerItem, false);
                    return triggerItem;
                });
            } else if (!_.isEmpty(_.get(res, 'triggerConfig.timeTriggers'))) {
                this.triggerList = _.map(_.get(res, 'triggerConfig.timeTriggers'), item => ({
                    type: 'TIME',
                    cron: item.cron
                }));
            }

            if (!_.isEmpty(_.get(res, 'conditionConfig.attributeConditions'))) {
                this.triggerList = _.map(_.get(res, 'conditionConfig.attributeConditions'), item => {
                    const triggerItem = {
                        type: 'DEVICE',
                        triggerType: 'ATTRIBUTE',
                        product: item.productId,
                        device: item.deviceId,
                        attribute: item.attributeKey,
                        operate: item.operate,
                        value: item.value,
                        deviceList: null,
                        attributeList: null
                    };
                    this.onDeviceTypeChange(item.productId, triggerItem, false);
                    this.onDeviceAttributeChange(item.attributeKey, triggerItem, false);
                    return triggerItem;
                });
            }

            if (!_.isEmpty(_.get(res, 'actionConfig.deviceMethodActions'))) {
                this.actionList = _.map(_.get(res, 'actionConfig.deviceMethodActions'), item => {
                    const actionItem = {
                        type: 'DEVICE_METHOD_ACTION',
                        product: item.productId,
                        device: item.deviceId,
                        method: item.methodId,
                        inputData: item.inputData,
                        sort: item.sort,
                        deviceList: null,
                        methodList: null
                    };
                    this.onDeviceTypeChange(item.productId, actionItem, false);
                    return actionItem;
                });
            } else if (!_.isEmpty(_.get(res, 'actionConfig.largeScreenNoticeActions'))) {
                this.actionList = _.map(_.get(res, 'actionConfig.largeScreenNoticeActions'), item => ({
                    type: 'LARGE_SCREEN_NOTICE',
                    actionType: item.type,
                    deviceLinkGroupId: item.groupId,
                    sort: item.sort
                }));
            }
            if (!this.triggerList || this.triggerList.length === 0) {
                this.addNewTrigger();
            }
            if (!this.conditionList || this.conditionList.length === 0) {
                this.addNewCondition();
            }
            if (!this.actionList || this.actionList.length === 0) {
                this.addNewAction();
            }
        });
    }
    addNewTrigger() {
        this.triggerList.push({ type: undefined, product: undefined, device: undefined, triggerType: undefined, attribute: undefined, event: undefined, operate: undefined, value: undefined, deviceList: null, eventList: null, attributeList: null });
    }
    removeTrigger(index: number) {
        this.triggerList.splice(index, 1);
    }
    addNewCondition() {
        this.conditionList.push({ type: undefined, product: undefined, device: undefined, attribute: undefined, operate: undefined, value: undefined, attributeModel: null, deviceList: null, attributeList: null });
    }
    removeCondition(index: number) {
        this.conditionList.splice(index, 1);
    }
    addNewAction() {
        this.actionList.push({ type: undefined, actionType: undefined, deviceLinkGroupId: undefined, product: undefined, device: undefined, method: undefined, inputData: null, deviceList: null, methodList: null });
    }
    removeAction(index: number) {
        this.actionList.splice(index, 1);
    }

    saveClick() {
        const params: any = { id: this.linkageConfigModel.id };
        if (this.IsEventTrigger) {
            params.triggerConfig = {
                eventTriggers: _.map(this.triggerList, item => ({
                    productId: item.product,
                    deviceId: item.device,
                    eventId: item.event
                }))
            };
            params.conditionConfig = {};
        } else if (this.IsAttributeTrigger) {
            params.triggerConfig = {
                attributeTriggers: _.map(this.triggerList, item => ({
                    productId: item.product,
                    deviceId: item.device,
                    attributeKey: item.attribute,
                    operate: item.operate,
                    value: item.value
                }))
            };
            // params.conditionConfig = {
            //     attributeConditions: _.map(this.triggerList, item => ({
            //         productId: item.product,
            //         deviceId: item.device,
            //         attributeKey: item.attribute,
            //         operate: item.operate,
            //         value: item.value
            //     }))
            // };
        } else if (this.IsTimeTrigger) {
            params.triggerConfig = {
                attributeTriggers: _.map(this.triggerList, item => ({
                    cron: item.cron
                }))
            };
        }
        // params.actionConfig = {
        //     deviceMethodActions: _.map(_.filter(this.actionList, item => item.type === 'DEVICE_METHOD_ACTION'), (item, index) => ({
        //         productId: item.product,
        //         deviceId: item.device,
        //         methodId: item.method,
        //         inputData: item.inputData,
        //         sort: index
        //     })),
        //     largeScreenNoticeActions: _.map(_.filter(this.actionList, item => item.type === 'LARGE_SCREEN_NOTICE'), (item, index) => ({
        //         type: item.actionType,
        //         groupId: item.deviceLinkGroupId,
        //         sort: index
        //     }))
        // };

        params.actionConfig = {
            deviceMethodActions: [],
            largeScreenNoticeActions: []
        };
        _.forEach(this.actionList, (item, index) => {
            if (item.type === 'DEVICE_METHOD_ACTION') {
                params.actionConfig.deviceMethodActions.push({
                    productId: item.product,
                    deviceId: item.device,
                    methodId: item.method,
                    inputData: item.inputData,
                    sort: index
                });
            } else if (item.type === 'LARGE_SCREEN_NOTICE') {
                params.actionConfig.largeScreenNoticeActions.push({
                    type: item.actionType,
                    groupId: item.deviceLinkGroupId,
                    sort: index
                });
            }
        });

        LinkageConfigService.saveConfig(params).then(res => {
            this.showMessageSuccess('保存成功');
            this.drawerClose();
        });
    }

    drawerClose() {
        this.linkageConfigModel = null;
        this.triggerList = [];
        this.conditionList = [];
        this.actionList = [];
        this.deviceTypeList = null;
        this.deviceLinkList = null;
        this.drawerVisible = false;
    }
}

