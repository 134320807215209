import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { dateFormat } from '@/filter';
import { alarmRuleStatusList } from '@/filter/alarm';

export class LinkageConfigEntityModel extends BaseEntityModel {
    @FormControl({
        label: '规则名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    enable: boolean = undefined;

    static getTableColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '运行状态',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enable' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class LinkageConfigQueryModel extends QueryPageModel {
    @QueryControl({
        label: '运行状态',
        type: QueryControlType.SELECT,
        options: alarmRuleStatusList,
        hasAllOption: true
    })
    enable: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '规则名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    toService() {
        return super.getParams();
    }
}
